"use client";

import React from "react";
import { gql } from "@apollo/client";
import { tv } from "tailwind-variants";
import { SomethingWentWrong } from "components/organisms";
import RedirectWithParams from "components/utilities/RedirectWithParams";
import {
	ExitedFundTag,
	OfferTags,
} from "components/organisms/opportunities/OfferTags";
import { useOffersQuery, useCheckForOpenTradesQuery } from "./page.generated";
import { OfferCard, OfferCardLoading } from "./OfferCard";

const wrapper = tv({
	base: "max-w-container-lg container mx-auto px-5 mt-10 grid gap-4 md:grid-cols-2 lg:gap-6",
});

function LoadingState() {
	return (
		<div className={wrapper()}>
			{[1, 2, 3, 4].map((key) => (
				<OfferCardLoading key={key} />
			))}
		</div>
	);
}

function RedirectWhenNoOffers() {
	const { data, loading } = useCheckForOpenTradesQuery();

	if (loading) {
		return <LoadingState />;
	}

	const hasSellOrders =
		data?.sellOrders &&
		data.sellOrders.filter((sellOrder) => sellOrder.status === "OPEN").length >
			0;

	if (hasSellOrders) {
		return <RedirectWithParams url="/trade" />;
	}

	return <RedirectWithParams url="/portfolio" />;
}

function NewOffers() {
	const { data, loading, error } = useOffersQuery();

	if (loading) {
		return <LoadingState />;
	}

	if (error) {
		return <SomethingWentWrong trigger={error} className="mt-40" />;
	}

	const { funds, offers } = data!;

	const exitedFunds = funds.filter((fund) => !!fund.exitDetails);

	const sortedOffers = offers
		.filter((offer) => !offer.hideOffer)
		.sort((a, b) => {
			// Sort by status - open offers come first
			if (a.status === "OPEN" && b.status !== "OPEN") {
				return -1;
			}

			if (a.status !== "OPEN" && b.status === "OPEN") {
				return 1;
			}

			return new Date(b.openDate).getTime() - new Date(a.openDate).getTime();
		});

	if (sortedOffers.length === 0 && exitedFunds.length === 0) {
		return <RedirectWhenNoOffers />;
	}

	return (
		<div className={wrapper()}>
			{sortedOffers.map((offer) => (
				<OfferCard
					key={`Offer-${offer.id}`}
					data-testid={`offer-card-${offer.slug}`}
					title={offer.name}
					blurb={offer?.subtitle || offer.fund.blurb}
					imageUrl={offer.cardImageUrl}
					tags={offer.status !== "CLOSED" && <OfferTags offer={offer} />}
					href={offer.status !== "CLOSED" ? `/invest/${offer.slug}` : undefined}
					grayscale={offer.status === "CLOSED"}
				/>
			))}

			{exitedFunds.map((fund) => (
				<OfferCard
					key={`Fund-${fund.id}`}
					data-testid={`exited-fund-card-${fund.id}`}
					title={fund.name}
					blurb={fund.blurb}
					imageUrl={fund.cardImageUrl}
					tags={<ExitedFundTag />}
					grayscale
				/>
			))}
		</div>
	);
}

export default function Page() {
	return <NewOffers />;
}

gql`
	${OfferTags.fragment}
	query Offers {
		offers {
			id
			slug
			name
			cardImageUrl
			status
			hideOffer
			openDate
			fund {
				id
				blurb
			}
			subtitle
			...OfferTags
		}
		funds {
			id
			name
			blurb
			cardImageUrl
			exitDetails {
				__typename
			}
		}
	}
	query CheckForOpenTrades {
		sellOrders {
			id
			status
		}
	}
`;
